@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

@font-face {
  font-family: 'Dico';
  src: local('Dico'), url(./fonts/Dico.ttf) format('truetype');
}

:root {
  background-color: black;
  height: 100%;
  width: 100%;
  font-family: 'Dico', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 0;
}

.title, .subtitle {
  text-align: center;
  color: rgb(165, 136, 97);
}

.subtitle {
  margin-bottom: 5vh;
}

.ipsumContainer {
  font-family: 'Dico', sans-serif;
  line-height: 1.9em;
  color: rgb(165, 136, 97);
  max-width: 75vw;
  background-color: rgba(0,0,0,.7);
  padding: 2vw;
  border-radius: 7px;
}

.copyButton {
  margin: 20px;
  padding: 10px 20px;
  font-family: 'Dico', sans-serif;
  background-color: rgb(67, 61, 58);
  border-radius: 5px;
  border: rgb(118, 91, 56);
  color: rgb(206, 205, 205);
}
.copyButton:hover {
  cursor: pointer;
  background-color: rgb(75, 42, 12);
  color: rgb(206, 205, 205);
}

#pixel-bonfire {
  width: 200px;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.youCopiedModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  background-color: rgba(0,0,0,.3);
  color: red;
  font-size: 10vw;
}